/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component } from "vue-property-decorator";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// styles source
import "./TermsAndConditions.scss";

export interface TermsAndConditions {
  paragraph: string;
}

@Component({
  components: {},
})
export default class UiPopup extends MetaView {
  /**
   * Terms and conditions
   *
   * @access readonly
   * @var {termsAndConditions}
   */
  get termsAndConditions(): TermsAndConditions[] {
    return [
      {
        paragraph: `      Nam interdum quam gravida et ante quis. Eget ultrices proin nullam id.
    Amet at ipsum aliquet sit nisl sed aenean. Velit ac lectus dui volutpat.
    Arcu varius ultricies purus lacus. Augue suspendisse in aliquam quis mi.
    Eget sed ultrices a sed in. Erat at viverra vel donec eu felis sed mi sem.
    Tristique convallis sit lectus sed sagittis commodo nullam nisl. Congue
    tristique gravida praesent sed. Duis ullamcorper bibendum arcu eget
    dignissim pharetra massa. Dignissim eu mollis tellus quisque erat ultrices
    condimentum. Purus tellus mattis enim adipiscing ut nisl bibendum. Eget
    ultricies nullam eget bibendum elementum in. Fermentum vel adipiscing
    ullamcorper amet vestibulum tristique nec blandit urna.`,
      },
      {
        paragraph: `      Tellus eu vulputate mattis mattis praesent eu. Bibendum dui amet platea at
        purus imperdiet etiam. Dolor amet mauris eget vulputate nisi. Hendrerit
        nisl viverra fusce est vitae erat dui nunc eget. Vitae vitae id quam at
        praesent leo ipsum. Donec cursus nulla faucibus nunc. Pulvinar neque diam
        amet quis interdum eu faucibus consectetur. Quis viverra habitasse sit
        proin semper ipsum nibh nec turpis. Eget aenean magna adipiscing sed.
        Morbi sem in quis purus vel scelerisque nisl hac. Elementum scelerisque
        ultricies interdum pellentesque non. Dolor sapien vitae velit massa. Mi a
        erat volutpat cras turpis vel in ridiculus semper.`,
      },
    ];
  }
}
