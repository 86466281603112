/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// styles source
import "./ConsentRequest.scss";
import { mapGetters } from "vuex";
import { RequestListItem } from "@/views/Dashboard/Dashboard";

@Component({
  components: { InlineSvg },
  computed: {
    ...mapGetters({
      getCurrentTransaction: "transactions/getCurrentTransaction",
    }),
  },
})
export default class ConsentRequest extends MetaView {
  /**
   * Current transaction getter
   *
   * @access public
   * @var {getCurrentTransaction}
   */
  public getCurrentTransaction: RequestListItem | undefined;
}
