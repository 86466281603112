/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component, Prop } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";
import { mapGetters } from "vuex";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// child components
import TopActivities from "../TopActivities/TopActivities.vue";
import ProgressBar from "../ProgressBar/ProgressBar.vue";
import InfoTip from "../InfoTip/InfoTip.vue";

// style resource
import "./Stats.scss";

export interface StatsConfig {
  amount: number;
  title: string;
  color: string;
}

@Component({
  components: {
    InlineSvg,
    TopActivities,
    ProgressBar,
    InfoTip,
  },
  computed: {
    ...mapGetters({
      currentUserAddress: "auth/getCurrentUserAddress",
    }),
  },
})
export default class Stats extends MetaView {
  /**
   * width of the component configuration
   *
   * @access readonly
   * @var {string}
   */
  @Prop({ default: () => ({}) })
  readonly width?: string;

  /**
   * width of the component configuration
   *
   * @access readonly
   * @var {StatsConfig}
   */
  @Prop({ default: () => ({}) })
  readonly data?: StatsConfig[];

  /**
   * This property contains the authenticated user's dHealth Account
   * Address. This field is populated using the Vuex Store after a
   * successful request to the backend API's `/me` endpoint.
   * <br /><br />
   * The `!`-operator tells TypeScript that this value is required
   * and the *public* access permits the Vuex Store to mutate this
   * value when it is necessary.
   *
   * @access public
   * @var {string}
   */
  public currentUserAddress!: string;
}
