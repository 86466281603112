/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */
import translationsOnboarding from "./OnboardingScreen.json";
import commonTranslations from "./Common.json";
import loginTranslations from "./LoginScreen.json";
import dashboardTranslations from "./Dashboard.json";
import settingsTranslations from "./Settings.json";
import componentsTranslations from "./Components.json";

// bundle all .JSON together
export default {
  langName: "en-US",
  ...translationsOnboarding,
  ...commonTranslations,
  ...loginTranslations,
  ...dashboardTranslations,
  ...settingsTranslations,
  ...componentsTranslations,
};
