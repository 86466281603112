/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// internal dependencies
import { HttpRequestHandler } from "@/kernel/remote/HttpRequestHandler";
import { BackendService } from "./BackendService";

export class TransactionService extends BackendService {
  /**
   * This property sets the request handler used for the implemented
   * requests. This handler forwards the execution of the request to
   * `axios`.
   *
   * @access protected
   * @returns {HttpRequestHandler}
   */
  protected get handler(): HttpRequestHandler {
    return new HttpRequestHandler();
  }

  /**
   * This method implements fetching of quick stats
   *
   * @param params
   * @returns
   */
  public getIncomingTransactionsRequests() {
    return [
      {
        title: "Lakeside Clinic Ltd.",
        avatarText: "Lc",
        avatarColor: "bg-tealMain",
        fileName: "Request to share ‘CT Scan-Head-20388.pdf’",
      },
      {
        title: "Rockport Dental Clinics Ltd.",
        avatarText: "RP",
        avatarColor: "bg-royalBlueMain",
        fileName: "Request to share ‘Dental Examination.pdf’",
      },
      {
        title: "Rockport Dental Clinics Ltd.",
        avatarText: "RP",
        avatarColor: "bg-royalBlueMain",
        fileName: "Request to share ‘Dental Examination.pdf’",
      },
      {
        title: "Virginia Institute Clinics",
        avatarText: "Vc",
        avatarColor: "bg-purpleMain",
        fileName: "Request to share ‘CT Scan-Head-20388.pdf’",
      },
      {
        title: "Virginia Institute Clinics",
        avatarText: "VI",
        avatarColor: "bg-purpleMain",
        fileName: "Request to share ‘CT Scan-ed8.pdf’",
      },
      {
        title: "Virginia Institute Clinics",
        avatarText: "VI",
        avatarColor: "bg-purpleMain",
        fileName: "Request to share ‘CT Scan-108.pdf’",
      },
    ];
  }

  public getTransactionsRequestsHistory() {
    return [
      {
        title: "Dental Examination.pdf",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "Dental Examination-2.pdf",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "X-Ray1382.pdf",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "X-Ray00082.pdf",
        avatarText: "RS",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "X-Ray0234982.pdf",
        avatarText: "RS",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "Dental Examination.pdf",
        avatarText: "RS",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "CT Scan-aj-288.pdf",
        avatarText: "RS",
        fileName: "Shared on 12.02.22",
      },
      {
        title: "CT Scan-ed8.pdf",
        avatarText: "RS",
        fileName: "Requested on 23.01.22",
        isDenied: true,
      },
      {
        title: "CT Scan-108.pdf",
        avatarText: "RS",
        fileName: "Requested on 23.01.22",
        isDenied: true,
      },
    ];
  }
}
