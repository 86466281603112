/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component, Prop } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// style resource
import "./Card.scss";

@Component({ components: { InlineSvg } })
export default class Card extends MetaView {
  /**
   * Title displayed on top of the card,
   * defaults to ""
   *
   * @access readonly
   * @var {title}
   */
  @Prop({ default: "" }) readonly title?: string;

  /**
   * Prop that defines width of the card
   *
   * @access readonly
   * @var {width}
   */
  @Prop({ default: "w-96" }) readonly width?: string;

  /**
   * Prop that defines icon of the card
   *
   * @access readonly
   * @var {icon}
   */
  @Prop({ default: "icons/info-tip.svg" }) readonly icon?: string;
}
