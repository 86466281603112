var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dapp-ui-popup"},[(_vm.config.overlayColor)?_c('div',{staticClass:"dapp-ui-popup__overlay",style:(`background-color: ${_vm.config.overlayColor}`),on:{"click":_vm.handleBgClick}}):_vm._e(),(_vm.config && _vm.config.type === 'stepper')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__form",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('inline-svg',{staticClass:"dapp-ui-popup__modal__form__close",attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":24},on:{"click":function($event){return _vm.$root.$emit('modal-close')}}}),_c('Stepper',{attrs:{"optionsList":_vm.options,"currentPosition":0}})],1):_vm._e(),(_vm.config && _vm.config.type === 'form')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__form",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"dapp-ui-popup__modal__form__close",on:{"click":function($event){return _vm.$root.$emit('modal-close')}}},[_c('inline-svg',{attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":24}})],1),_c('h2',{staticClass:"dapp-ui-popup__modal__form__title"},[_vm._v(_vm._s(_vm.config.title))]),_vm._l((_vm.config.fields),function(field,index){return _c('div',{key:field.name + index,staticClass:"input-wrapper"},[_c('input',{staticClass:"dynamic-input",attrs:{"type":"text","placeholder":field.placeholder,"name":field.name},on:{"input":_vm.handleInput}})])}),_c('UiButton',{attrs:{"disabled":!_vm.isFormFilled},on:{"click":_vm.handleFormSubmission}},[_vm._v("Submit")])],2):_vm._e(),(_vm.config && _vm.config.type === 'notification')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__notification",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"dapp-ui-popup__modal__notification__close",on:{"click":function($event){return _vm.$root.$emit('modal-close')}}},[_c('inline-svg',{attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":32}})],1),(_vm.config.illustration)?_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.getImageUrl(_vm.config.illustration),"alt":_vm.config.title || _vm.config.description}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.config.description)}})]):_vm._e()]):_vm._e(),(_vm.config && _vm.config.type === 'share')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__share",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"flex flex-row justify-between items-center mb-[32px]"},[_c('div',{staticClass:"row-item"},[_c('h3',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$t('common.word_share'))}})]),_c('div',{staticClass:"row-item"},[_c('inline-svg',{staticClass:"dapp-ui-popup__modal__notification__close",attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":32},on:{"click":function($event){return _vm.$root.$emit('modal-close')}}})],1)]),_c('div',{staticClass:"flex flex-row mb-[32px]"},_vm._l((_vm.socialPlatforms),function(item,index){return _c('div',{key:index + item.title,staticClass:"social-item"},[_c('a',{attrs:{"href":item.shareUrl,"target":"_blank","rel":"noopener"}},[_c('img',{attrs:{"src":_vm.getImageUrl(`${item.icon}`),"alt":item.title}}),_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(item.title)}})])])}),0),_c('ReferralInput',{attrs:{"type":"link","val":_vm.refCode}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }