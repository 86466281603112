/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// styles source
import "./ConfirmConsent.scss";

export interface Steps {
  description: string;
  icon: string;
}

@Component({
  components: { InlineSvg },
})
export default class ConfirmConsent extends MetaView {
  get steps(): Steps[] {
    return [
      {
        description:
          "Go to Google Play /App Store and download the dHealth Signer App",
        icon: "Lifesavers UI Window.svg",
      },
      {
        description:
          "Open the Signer Application on your mobile device and tap ‘Scan’",
        icon: "scan_with_phone.svg",
      },
      {
        description: "Scan the following QR code to confirm consent.",
        icon: "barcode_sample.svg",
      },
    ];
  }
}
