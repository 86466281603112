/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component, Prop } from "vue-property-decorator";

// internal dependencies
import { MetaView } from "@/views/MetaView";
import InlineSvg from "vue-inline-svg";

// child components
import TopActivities from "../TopActivities/TopActivities.vue";
import ProgressBar from "../ProgressBar/ProgressBar.vue";
import InfoTip from "../InfoTip/InfoTip.vue";
import { RequestListItem } from "@/views/Dashboard/Dashboard";

// style resource
import "./RequestList.scss";

export interface RequestsConfig {
  title: string;
  avatarColor: string;
  avatarText: string;
  fileName: string;
  isDenied: boolean;
}

@Component({
  components: {
    InlineSvg,
    TopActivities,
    ProgressBar,
    InfoTip,
  },
})
export default class RequestList extends MetaView {
  /**
   * RequestList component configuration
   *
   * @access readonly
   * @var {data}
   */
  @Prop({ default: () => ({}) }) readonly data?: RequestsConfig[];

  /**
   * width of the component configuration
   *
   * @access readonly
   * @var {string}
   */
  @Prop({ default: () => ({}) }) readonly width?: string;

  /**
   * type of the component configuration
   *
   * @access readonly
   * @var {boolean}
   */
  @Prop({ default: () => ({}) }) readonly isHistory?: boolean;

  protected async setSelectedTransaction(provider: RequestListItem) {
    await this.$store.dispatch("transactions/setCurrentTransaction", provider);
  }

  public async showModal(item: RequestListItem): Promise<void> {
    await this.setSelectedTransaction(item);
    this.$root.$emit("modal", {
      overlayColor: "gba(60, 108, 64, 0.1)",
      type: "stepper",
      modalBg: "#FFFFFF",
      width: 766,
    });
  }
}
