/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component, Prop } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

// internal dependencies
import { MetaView } from "@/views/MetaView";
import ConsentRequest from "../ConsentRequest/ConsentRequest.vue";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions.vue";
import ConfirmConsent from "../ConfirmConsent/ConfirmConsent.vue";

// styles source
import "./Stepper.scss";

export interface Options {
  header: string;
}
@Component({
  components: { InlineSvg, ConsentRequest, TermsAndConditions, ConfirmConsent },
})
export default class Stepper extends MetaView {
  /**
   * Prop which defines list and content of stepper
   * .step - field which defines stepper position
   *
   * @access readonly
   * @var {optionsList}
   */
  @Prop({ default: () => [] })
  readonly optionsList!: Options[];

  /**
   * Prop which defines initially selected stepper
   * value shouldn't be > options.length
   *
   * @access readonly
   * @var {currentPosition}
   */
  @Prop({ default: 0 })
  public currentPosition!: number;

  /**
   * This method sets number of currently shown step
   * if {currentPosition} prop is less than the length - 1 of the total number of steps - emits event with value from step
   *
   * @access public
   */
  next() {
    if (this.currentPosition < this.optionsList.length - 1) {
      this.currentPosition++;
    }
  }
  prev() {
    if (this.currentPosition > 0) {
      this.currentPosition--;
    }
  }
}
