/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vuex Store
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { ActionContext } from "vuex";

// internal dependencies
import { TransactionService } from "@/services/TransactionService";
import { RequestListItem } from "./../../views/Dashboard/Dashboard";
import { AwaitLock } from "../AwaitLock";
import { RootState } from "./Store";

// creates an "async"-lock for state of pending initialization
// this will be kept *locally* to this store module implementation
const Lock = AwaitLock.create();

export interface TransactionsModuleState {
  initialized: boolean;
  currentTransaction: RequestListItem | undefined;
  incomingTransactions: RequestListItem | undefined;
  transactionHistory: RequestListItem | undefined;
}

/**
 *
 */
export type TransactionModuleContext = ActionContext<
  TransactionsModuleState,
  RootState
>;

export const TransactionsModule = {
  // this store module is namespaced, meaning the
  // module name must be included when calling a
  // mutation, getter or action, i.e. "integrations/getIntegrations".
  namespaced: true,
  state: (): TransactionsModuleState => ({
    initialized: false,
    currentTransaction: undefined,
    incomingTransactions: undefined,
    transactionHistory: undefined,
  }),

  getters: {
    // getContract: (state: TransactionsModuleState): Contract | undefined =>
    //   state.currentTransaction.contract,

    // getPublicAccount: (state: TransactionsModuleState): string | undefined =>
    //   state.currentTransaction?.signer?.publicKey,

    // getScope: (state: TransactionsModuleState): string | undefined =>
    //   state.currentTransaction.contract?.body,

    // getPurpose: (state: TransactionsModuleState): string | undefined =>
    //   state.currentTransaction.contract?.body,

    getIncomingTransactions: (
      state: TransactionsModuleState
    ): RequestListItem | undefined => state.incomingTransactions,

    getTransactionsHistory: (
      state: TransactionsModuleState
    ): RequestListItem | undefined => state.transactionHistory,

    getCurrentTransaction: (
      state: TransactionsModuleState
    ): RequestListItem | undefined => state.currentTransaction,
  },

  mutations: {
    /**
     *
     */
    setInitialized: (
      state: TransactionsModuleState,
      payload: boolean
    ): boolean => (state.initialized = payload),

    /**
     *
     */
    setIncomingTransaction: (
      state: TransactionsModuleState,
      payload: RequestListItem
    ): RequestListItem => (state.incomingTransactions = payload),

    setTransactionHistory: (
      state: TransactionsModuleState,
      payload: RequestListItem
    ): RequestListItem => (state.transactionHistory = payload),

    setCurrentTransaction: (
      state: TransactionsModuleState,
      payload: RequestListItem
    ): RequestListItem => (state.currentTransaction = payload),
  },

  actions: {
    /**
     *
     */
    async fetchIncomingTransactionRequests(context: TransactionModuleContext) {
      const service = new TransactionService();
      try {
        const transactions = service.getIncomingTransactionsRequests();
        context.commit("setIncomingTransaction", transactions);
      } catch (err) {
        console.log("Error, transactions/fetchIncomingTransaction:", err);
        throw err;
      }
    },

    async fetchTransactionRequestsHistory(context: TransactionModuleContext) {
      const service = new TransactionService();
      try {
        const transactions = service.getTransactionsRequestsHistory();
        context.commit("setTransactionHistory", transactions);
      } catch (err) {
        console.log("Error, transactions/setCurrentTransaction:", err);
        throw err;
      }
    },

    async setCurrentTransaction(
      context: TransactionModuleContext,
      transaction: RequestListItem
    ) {
      try {
        context.commit("setCurrentTransaction", transaction);
      } catch (err) {
        console.log("Error, transactions/fetchTransactionHistory:", err);
        throw err;
      }
    },
    /**
     *
     */
    async initialize(context: TransactionModuleContext): Promise<boolean> {
      const callback = async () => {
        // if incoming transactions are not loaded
        if (!context.state.incomingTransactions) {
          // loads incoming transactions for current user
          await context.dispatch("fetchIncomingTransactionRequests");
        }
        // if transactions history are not loaded
        if (!context.state.transactionHistory) {
          // loads transactions history for current user
          await context.dispatch("fetchTransactionRequestsHistory");
        }

        // initialization is done after fetch
        context.commit("setInitialized", true);
      };
      // acquire async lock until initialized
      await Lock.initialize(callback, context);
      return true;
    },
  },
};
