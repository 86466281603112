/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { Component, Prop } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

// internal dependencies
import { MetaView } from "@/views/MetaView";

// style resource
import "./UserBalance.scss";

@Component({
  components: {
    InlineSvg,
  },
})
export default class UserBalance extends MetaView {
  /**
   * This property defines amount of balance that will be outputed
   *
   * @var {number}
   */
  @Prop({ default: 0 }) userBalance?: number;
}
